import { Component, OnInit } from '@angular/core';

export type lunofile = {
  name:string,
  alias:string,
  label:string
}
@Component({
  selector: 'app-download',
  templateUrl: './download.component.html',
  styleUrls: ['./download.component.css']
})
export class DownloadComponent implements OnInit {
  fileCollection: lunofile[] = [
    {name:"00-Regolamento-della-lunoteca.pdf", alias:"Regolamento", label:"Regolamento della lunoteca"},
    {name:"02-Regolamento-eventi.pdf", alias:"RegolamentoEventi", label:"Regolamento eventi in lunoteca"},
    {name:"01-Modulo-iscrizione.pdf", alias:"ModuloIscrizione", label:"Modulo iscrizione"},
    {name:"03-Delega-ritiro.pdf", alias:"ModuloDelegaRitiro", label:"Modulo per delega ritiro a scuola"},
  ];

  constructor() {

  }

  ngOnInit() {
  }

}
