import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-icon',
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.css']
})
export class IconComponent implements OnInit {
  @Input() isBreaked: boolean = false;
  @Input() icon: string;
  @Input() iconBackColor: string | object;
  @Input() iconColor: string;
  @Input() size: number = 1;
  @Input() isBack: boolean = true;
  @Input() forceInline: boolean = false;
  @Input() isPulled: boolean = false;
  @Input() isRight: boolean = false;
  @Input() isActive: boolean = false;
  @Input() isSquare: boolean = false;
  @Input() haveContent: boolean = true;

  constructor() { }

  ngOnInit() {
  }

  getIcon(){
    return this.icon?this.icon:'fas fa-question'
  }
  getIconBackColor(isStyle = false){
    return this.getColor(this.iconBackColor,isStyle);

  }
  getIconColor(isStyle = false){
    return this.getColor(this.iconColor,isStyle);
  }

  isInline() {
    return this.forceInline?'-inline':'';
  }

  private getColor(color: string| object,isStyle: boolean): string | object{
    if(color) {
      let includes = false;
      if(typeof color === 'object'){
        return isStyle?color:'';
      }else {
        includes = color.includes('#') || color.includes('(');
        if (isStyle && includes) {
          return {'color': color};
        } else {
          return (!isStyle && !includes) ? color : '';
        }
      }
    }
    return '';
  }
}
