import {Component, HostListener, OnInit} from '@angular/core';
import {ScrollSpyService} from "../../services/scrollSpy/scroll-spy.service";
import {Link} from "../../classes/link/link";
import {environment} from "../../../environments/environment";

@Component({
  selector: 'app-debug',
  templateUrl: './debug.component.html',
  styleUrls: ['./debug.component.css']
})
export class DebugComponent implements OnInit {
  isVisible: boolean = false;
  scroll: any;
  active: number = -1;
  links: Link[] = [];


  @HostListener('window:scroll', ['$event']) onScrollEvent($event){
    this.scroll = window.scrollY;
    this.active = Link.getActiveIndex(this.links);
  }
  constructor() {
    ScrollSpyService.instance.getLinkObservable().subscribe((links)=>{
      //console.log(links);
      this.links = links;
    })
  }

  ngOnInit() {
  }

}
