import {Component, Input, OnInit} from '@angular/core';
import {ImgCarousel} from "../carousel/carousel.component";

@Component({
  selector: 'app-polaroid',
  templateUrl: './polaroid.component.html',
  styleUrls: ['./polaroid.component.scss']
})
export class PolaroidComponent implements OnInit {
  @Input()  img: ImgCarousel;

  constructor() { }

  ngOnInit() {
  }

}
