import {Component, ElementRef, HostListener, OnInit, ViewChild} from '@angular/core';
import {ScrollSpyService} from "../../services/scrollSpy/scroll-spy.service";
import {environment} from "../../../environments/environment";

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.css']
})
export class ModalComponent implements OnInit {

  newsLink = null;
  isFirst = true;
  newsCollection: any[] = [
    '../../../assets/Img/News/giugno.jpg',
    '../../../assets/Img/News/flyer.jpg'
  ];

  @ViewChild('news_modal', {static: false}) modal: ElementRef;
  private buttonPos: number = 0;
  constructor() {
    ScrollSpyService.instance.getLinkObservable().subscribe((links)=>{
      //console.log('scrollspy:',links);
      this.newsLink = links[0];
    })
  }

  @HostListener('window:scroll', ['$event']) onScrollEvent($event){
    let showPos = this.newsLink.scrollPos - environment.scrollAdjust;
    if (window.scrollY >= showPos) {
      this.buttonPos = window.scrollY;
      if(this.isFirst){
        this.isFirst = false;
        (this.modal.nativeElement as HTMLButtonElement).click();
      }
    }else{
      this.buttonPos = window.scrollY;
    }
  }

  ngOnInit() {
  }

  newsBtnClass() {
    if(this.isFirst){
      return 'animateEvidence';
    }
    return 'animate';
  }

  getNewsButtonPosition() {
    return {
      'bottom':`${this.buttonPos}px`
    }
  }
}
