import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.css']
})
export class CardComponent implements OnInit {
  @Input() image: string;
  @Input() bottom: boolean;

  constructor() { }

  ngOnInit() {
  }

  getStyle() {
    if(this.image)
      return {
      'background-image':`url(${this.image}`,
      'background-size':'cover',
      'background-repeat':'no-repeat',
      'padding-top': '100%',
      'position': 'relative'
    };
    return '';
  }
}
