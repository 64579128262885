import { Injectable } from '@angular/core';
import {Link} from "../../classes/link/link";
import {Observable, Subject} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class ScrollSpyService {
  public links: Link[] = [];
  public linkSubject: Subject<Link[]> = new Subject<Link[]>();
  static instance: ScrollSpyService;

  public getLinkObservable(){
    return this.linkSubject.asObservable();
  }

  constructor() {
    ScrollSpyService.instance = this;
  }

  init() {
    //console.log('createdScrollSpy')
  }
}
