import {
  AfterContentInit,
  AfterViewInit,
  Component,
  ElementRef,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {Product} from "../../classes/product/product";
import {ScrollSpyService} from "../../services/scrollSpy/scroll-spy.service";
import {Link} from "../../classes/link/link";
import {Subject} from "rxjs";
import {ImgCarousel} from "../../components/helper/carousel/carousel.component";

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  productCollection: Product[];

  @ViewChild('news',{static:false}) news: ElementRef<HTMLElement>;
  @ViewChild('orari',{static:false}) orari: ElementRef<HTMLElement>;
  @ViewChild('servizi',{static:false}) servizi: ElementRef<HTMLElement>;
  @ViewChild('storia',{static:false}) storia: ElementRef<HTMLElement>;
  @ViewChild('prezzi',{static:false}) prezzi: ElementRef<HTMLElement>;
   /*
  */

  newsPosSubject: Subject<number> = new Subject();
  orariPosSubject: Subject<number> = new Subject();
  serviziPosSubject: Subject<number> = new Subject();
  prezziPosSubject:Subject<number> = new Subject();
  storiaPosSubject:Subject<number> = new Subject();

  newsLink = new Link('news', 'news', this.newsPosSubject.asObservable(), 'news', 'far fa-bookmark');
  orariLink = new Link('orari', 'orari', this.orariPosSubject.asObservable(), 'orari', 'far fa-clock');
  serviziLink=  new Link('servizi', 'servizi', this.serviziPosSubject.asObservable(), 'servizi', 'far fa-moon',);
  prezziLink = new Link('prezzi', 'prezzi', this.prezziPosSubject.asObservable(), 'tariffe', 'far fa-star',);
  storiaLink = new Link('storia', 'storia', this.storiaPosSubject.asObservable(), 'staff', 'fas fa-heart', 'text-danger');
  downloadLink = new Link('download','download',null,'download', 'fas fa-file-download');
  galleryLink = new Link('gallery','gallery',null,'gallery', 'fas fa-photo-video');

  constructor() {
    this.serviziLink.addSublink([this.downloadLink/*,this.galleryLink*/])
  }

  ngOnInit() {
    ScrollSpyService.instance.linkSubject.next([
      this.newsLink,
      this.orariLink,
      this.serviziLink,
      this.prezziLink,
      this.storiaLink,
    ]);
    let thread = setInterval(() => {
      //console.log(this.orari);
      if (this.orari.nativeElement) {
        this.newsPosSubject.next(this.news.nativeElement.offsetTop);
        this.orariPosSubject.next(this.orari.nativeElement.offsetTop);
        this.serviziPosSubject.next(this.servizi.nativeElement.offsetTop);
        this.storiaPosSubject.next(this.storia.nativeElement.offsetTop);
        this.prezziPosSubject.next(this.prezzi.nativeElement.offsetTop);
        /*
        */
      }
    },250)
  }

}
