import { Component, OnInit } from '@angular/core';
import {ImgCarousel} from "../helper/carousel/carousel.component";

@Component({
  selector: 'app-storia',
  templateUrl: './storia.component.html',
  styleUrls: ['./storia.component.scss']
})
export class StoriaComponent implements OnInit {
  perlaBoomerang: ImgCarousel = {src:'/Img/Polaroid/perla2.gif', title:'Perla<br>Rumori', description:''};
  annaBoomerang: ImgCarousel = {src:'/Img/Polaroid/A5.gif', title:'Annalisa<br>Rossini', description:''};
  silviaBoomerang: ImgCarousel = {src:'/Img/Polaroid/silvia.gif', title:'Silvia<br>Basso', description:''};
  martinaBoomerang: ImgCarousel = {src:'/Img/Polaroid/A4.gif', title:'Martina<br>Mangialardo', description:''};
  serenaBoomerang: ImgCarousel = {src:'/Img/Polaroid/A6.gif', title:'Serena<br>Ricchi', description:''};
  boomerangCollection: ImgCarousel[] = [
    this.perlaBoomerang,
    this.annaBoomerang,
    this.silviaBoomerang,
    this.martinaBoomerang,this.serenaBoomerang
  ];


  constructor() { }

  ngOnInit() {

      var rotateComplete = function() {
        target.style.webkitAnimationName = "";
        target.insertBefore(arr[arr.length-1], arr[0]);
        setTimeout(function(el) {
          el.style.webkitAnimationName = "rotator";
        }, 0, target);
      };

      var target = document.getElementById("rotator");
      var arr = target.getElementsByClassName("polaroid-stage");

      target.addEventListener("webkitAnimationEnd", rotateComplete, false);

  }

}
