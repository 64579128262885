import {AfterViewInit, Component, EventEmitter, OnInit, Output} from '@angular/core';
import {BasicComponentEvent} from "../../classes/basicEvents/basicComponentEvent";
import * as moment from 'moment';
import {animate, state, style, transition, trigger} from "@angular/animations";
@Component({
  selector: 'app-orari',
  templateUrl: './orari.component.html',
  styleUrls: ['./orari.component.css'],
  animations: [
    trigger('changeDivSize', [
      state('initial', style({
        backgroundColor: 'green',
        width: '100%',
      })),
      state('invisible', style({
        backgroundColor: 'yellow',
        opacity: '0'
      })),
      state('final', style({
        backgroundColor: 'red',
        width: '100%',
      })),
      transition('initial=>invisible', animate('100ms')),
      transition('invisible=>final', animate('200ms')),
      transition('invisible=>initial', animate('200ms')),
      transition('final=>invisible', animate('100ms'))
    ]),
  ]
})
export class OrariComponent implements OnInit {
  // @ts-ignore
  imagePath = require('../../../assets/Img/lab2.jpg');


  isScuoleChiuse: boolean;
  constructor() {
    let now = moment();
    let m = now.month();
    //le scuole sono normalemnte chiuse da giugno a settembre
    this.isScuoleChiuse = (5 <= m && m <= 9)
  }

  currentState = 'initial';

  changeState() {
    this.currentState = 'invisible';
    setTimeout(()=>{
      this.currentState = this.isScuoleChiuse? 'final' : 'initial';
    },250)
  }

  ngOnInit() {
  }

  switchOrario() {
    this.isScuoleChiuse = !this.isScuoleChiuse;
    this.changeState();
  }

  getStickies() {
    return [
      {title: '<h2>Orari scuole<br>aperte</h2>', content: '<p class="h4>">Dal Lunedì al Sabato<br>dalle 16:00 alle 20:15</p>'},
      {title: '<h2>Orari scuole<br>chiuse</h2>', content: '<p class="h4>">Dal Lunedì al Venerdì<br>dalle 7:30 alle 16:30</p>'},
    ]
  }
}
