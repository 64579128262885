import { Component, OnInit } from '@angular/core';
import {ImgCarousel} from "../helper/carousel/carousel.component";

@Component({
  selector: 'app-head-logo',
  templateUrl: './head-logo.component.html',
  styleUrls: ['./head-logo.component.css']
})
export class HeadLogoComponent implements OnInit {
  imgCollection: ImgCarousel[] = [
    {title:'Cani', description:'cittadella durante la lezione con i cani', src:'Carousel/cittadella1.jpeg'},
    {title:'FOTO 1', description:'nulla da dire', src:'Carousel/cittadella2.jpeg'},
    {title:'FOTO 1', description:'nulla da dire', src:'Carousel/ape1.jpeg'},
    {title:'FOTO 1', description:'nulla da dire', src:'Carousel/lanterna1.jpeg'},
    {title:'FOTO 1', description:'nulla da dire', src:'Carousel/lanterna2.jpeg'},
    {title:'FOTO 1', description:'nulla da dire', src:'Carousel/lanterna3.jpeg'},
  ];
  constructor() { }

  ngOnInit() {
  }

}
