import { Component, OnInit } from '@angular/core';
import {FlipClass} from "../helper/flip/flip.component";
import {ImgCarousel} from "../helper/carousel/carousel.component";

@Component({
  selector: 'app-servizi',
  templateUrl: './servizi.component.html',
  styleUrls: ['./servizi.component.scss']
})
export class ServiziComponent implements OnInit {
  private size: any;

  serviceCollection: FlipClass[] = [
    {
      front:`
<div class="text-center">
  <img src="../../../assets/Img/ServImg/baby_parking.png" class=" img-service">
  <div class="service-content">
    <h2>Baby Parking</h2>
    <p class="h4">compagnia, gioco e divertimento<br>La sicurezza di uno spazio protetto per ogni tuo bisogno</p>
    <div class="badge service-badge">3+</div>
  </div>
</div>`,
      back:`
<div class="text-center">
    <img src="../../../assets/Img/ServImg/baby_parking.png" class=" img-back">
    <p class="h3 txt-back">Uno spazio protetto, curato e con orari flessibili per bimbi che hanno voglia di ridere e giocare in compagnia, scoprendo passioni nuove e costruendo grandi amicizie</p>
</div>`,
      size:this.size,
    },
    {
      front:`
<div class="text-center">
  <img src="../../../assets/Img/ServImg/laboratori.png" class=" img-service">
  <div class="service-content">
    <h2>Laboratori</h2>
    <p class="h4">usare la testa e le mani per imparare, scoprendo nuovi interessi</p>
  </div>
</div>`,
      back:`
<div class="text-center">
    <img src="../../../assets/Img/ServImg/laboratori.png" class=" img-back">
    <p class="h3 txt-back">C'è sempre un momento per creare, per imparare a costruire qualcosa o per analizzare uno strano campione al microscopio.<br>Che cosa ti appassionerà oggi?</p>
</div>`,
    },
    {
      front:`
<div class="text-center">
  <img src="../../../assets/Img/ServImg/aiuto_compiti.png" class=" img-service">
  <div class="service-content">
    <h2>Aiuto compiti</h2>
    <p class="h4">spazio esclusivo per attività di concentrazione con personale formato e dedicato</p>
  </div>
</div>`,
      back:`
<div class="text-center">
    <img src="../../../assets/Img/ServImg/aiuto_compiti.png" class=" img-back">
    <p class="h3 txt-back">In compagnia e con il giusto approccio, ogni cosa può diventare più semplice e leggera.<br>Perché i piccoli, grandi impegni possono diventare una buona abitudine</p>
</div>`,
    },
    {
      front:`
<div class="text-center">
  <img src="../../../assets/Img/ServImg/serale.png" class=" img-service">
  <div class="service-content">
    <h2>Baby Happy Hour</h2>
    <p class="h4">Il Venerdì e il Sabato baby parking con orario prolungato fino alle 23:00</p>
    <div class="badge service-badge">3+</div>
  </div>
</div>`,
      back:`
<div class="text-center">
    <img src="../../../assets/Img/ServImg/serale.png" class=" img-back">
    <p class="h3 txt-back">Cena in compagnia, giochi e tanto divertimento.<br>Goditi la tua serata, tuo figlio farà lo stesso! Prenotazione obbligatoria entro le 18:00</p>
</div>`,
    },
    {
      front:`
<div class="text-center">
  <img src="../../../assets/Img/ServImg/corsi.png" class=" img-service">
  <div class="service-content">
    <h2>Corsi</h2>
    <p class="h4">Per grandi passioni, servono grandi insegnanti.<br>Contattaci e scopri i corsi attivi!</p>
  </div>
</div>`,
      back:`
<div class="text-center">
    <img src="../../../assets/Img/ServImg/corsi.png" class=" img-back">
    <p class="h3 txt-back">Teatro, musica, robotica, yoga, psicomotricità e cinofilia per bambini...<br>Ce ne sono per tutti i gusti e per tutte le età!</p>
</div>`,
    },
    {
      front:`
<div class="text-center">
  <img src="../../../assets/Img/ServImg/centri_estivi.png" class=" img-service">
  <div class="service-content">
    <h2>Centri festivi</h2>
    <p class="h4">Scuole chiuse, Lunoteca aperta!<br>Aperti dalle 7:30 in ogni periodo dell'anno,<br>Agosto compreso</p>
    <div class="badge service-badge">3+</div>
  </div>
</div>`,
      back:`
<div class="text-center">
    <img src="../../../assets/Img/ServImg/centri_estivi.png" class=" img-back">
    <p class="h3 txt-back">Giochi all'aria aperta, attività per ogni età e corsi coinvolgenti, in assoluta sicurezza e con il massimo del divertimento.<br>Alla scoperta della nostra città a misura di bambino</p>
</div>`,
    },
    {
      front:`
<div class="text-center">
  <img src="../../../assets/Img/ServImg/eventi.png" class=" img-service">
  <div class="service-content">
    <h2>Sale per eventi</h2>
    <p class="h4">sale ampie con uso cucina, uso giochi, uso cassa musicale e pulizia compresa</p>
  </div>
</div>`,
      back:`
<div class="text-center">
    <img src="../../../assets/Img/ServImg/eventi.png" class=" img-back">
    <p class="h3 txt-back">Spazi ampi e ben organizzati, convenzione con il parcheggio Stamira e massima comodità: l'unico limite è la fantasia, ma se vi serve abbiamo anche quella</p>
</div>`,
    },
    {
      front:`
<div class="text-center">
  <img src="../../../assets/Img/ServImg/animazione.png" class=" img-service">
  <div class="service-content">
    <h2>Animazione</h2>
    <p class="h4">Chiedici qualcosa di speciale, siamo pronte a rendere unico ogni evento!</p>
  </div>
</div>`,
      back:`
<div class="text-center">
    <img src="../../../assets/Img/ServImg/animazione.png" class=" img-back">
    <p class="h3 txt-back">Trucco, laboratori e palloncini?<br>Sì, ma non solo.<br>Bolle di sapone, musica e  giochi?<br>Certo, ma anche... Qualunque cosa ti venga in mente!<br>La nostra specialità è creare feste a tema ed eventi unici: lanciaci una sfida!</p>
</div>`,
    }
    /*,{
      front:`
<div class="text-center">
  <img src="../../../assets/Img/ServImg/lunobus.png" class=" img-service">
  <div class="service-content">
    <h2>lunobus</h2>
    <p class="h4">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Consequatur dignissimos ducimus error et itaque iure natus, perspiciatis praesentium quae quasi sequi unde voluptate voluptates. Dignissimos labore mollitia nam praesentium voluptates.</p>
  </div>
</div>`,
      back:`
<div class="text-center">
    <img src="../../../assets/Img/ServImg/lunobus.png" class=" img-back">
    <p class="h3 txt-back">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ea repudiandae tenetur vitae. A distinctio dolorem est ipsa laudantium molestiae nisi odio quidem, quisquam, quod recusandae sequi tenetur ut voluptas voluptatem.</p>
</div>`,
    },{
      front:`
<div class="text-center">
  <img src="../../../assets/Img/ServImg/genitore.png" class=" img-service">
  <div class="service-content">
    <h2>genitore</h2>
    <p class="h4">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet dolor est, eveniet, hic, illum labore rem reprehenderit sapiente sed sunt vel voluptatibus. Ab hic laudantium necessitatibus quibusdam repellendus reprehenderit sunt.</p>
  </div>
</div>`,
      back:`
<div class="text-center">
    <img src="../../../assets/Img/ServImg/genitore.png" class=" img-back">
    <p class="h3 txt-back">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ad alias asperiores aspernatur at, atque, cumque eaque earum error ex facilis fugiat labore nobis placeat, quidem quisquam sit soluta vel voluptatem!</p>
</div>`,
    },*/
  ];


  flipped: boolean = false;


  constructor() {

  }


  ngOnInit() {
  }

  toggleFlip() {
    this.flipped = !this.flipped
  }
}
