import {Observable} from "rxjs";
import {environment} from "../../../environments/environment";

export class Link {
  icon: string;
  label: string;
  name: string;
  link: string;
  backColor: string;
  iconColor: string;
  scrollPos: number;
  isActive: boolean;
  sublink: Link[];

  constructor(name:string, link: string, scrollPos: Observable<number> | null, label?: string, icon?, iconColor?, backColor?){
    this.link = link;
    this.label = label;
    if(scrollPos)
      scrollPos.subscribe((pos)=>{
        this.scrollPos = pos;
      })
    this.name = name;
    this.icon = icon?icon:null;
    this.iconColor = iconColor?iconColor:null;
    this.backColor = backColor?backColor:null;
  }

  addSublink(links: Link[]){
    this.sublink = links;
  }

  static getActiveIndex(links): number{
    links.forEach((link,index,coll)=>{
      let posCurrent = link.scrollPos - environment.scrollAdjustClick;
      if (window.scrollY >= posCurrent) {
        link.isActive = true;
      }else{
        link.isActive = false;
      }
    })
    return links.filter((l)=>{return l.isActive }).length -1;
  }

}
