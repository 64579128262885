import {AfterContentInit, ChangeDetectorRef, Component, HostListener, OnInit} from '@angular/core';
import {environment} from "../../../environments/environment";
import {Link} from "../../classes/link/link";
import {Location} from "@angular/common";
import { ScrollSpyService} from "../../services/scrollSpy/scroll-spy.service";
import {ImgCarousel} from "../helper/carousel/carousel.component";
import {Router} from "@angular/router";

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {
  isHomeVisible: boolean = true;

  public linkCollection: Link[] =  [];

  private currentRoute: string = 'home';
  constructor(private location: Location, private router: Router, private ref: ChangeDetectorRef) {

  }

  ngOnInit() {
    this.ref.detach();
    this.location.onUrlChange((url,state)=>{
      //console.log('location:',url,state);
      this.currentRoute = url;
      this.isHomeVisible = url.includes('home');
      //if(url.includes())
      //window.scroll(0,0)
    });
    ScrollSpyService.instance.getLinkObservable().subscribe((links)=>{

      //console.log(links);
      this.linkCollection = links;
      this.ref.detectChanges();
    })
    this.win = window;
  }

  activeIndex: number = -1;
  win: any;
  @HostListener('window:scroll', ['$event']) onScrollEvent($event){
   this.activeIndex = Link.getActiveIndex(this.linkCollection);
   this.ref.markForCheck();
   this.ref.detectChanges();
  }

  isActive(index: number) {
    return this.activeIndex === index;
  }

  scrollTo(link: Link) {
    //console.log(link)
    window.scrollTo(0,link.scrollPos - environment.scrollAdjust);
  }

  getLinkCollection() {
    return this.linkCollection;
  }

  navigate(link: string) {
    this.router.navigate([link]);
  }


}
