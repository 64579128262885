import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-title',
  templateUrl: './title.component.html',
  styleUrls: ['./title.component.scss']
})
export class TitleComponent implements OnInit {
  @Input() title: string;
  @Input() icon: string;
  cloud: number = 0;
  constructor() {
    /*setInterval(()=>{
      this.cloud = Math.floor(Math.random() * 5);
    },1000)*/

  }

  ngOnInit() {
  }

}
