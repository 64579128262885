import {Component, Input, OnInit} from '@angular/core';

export type ImgCarousel = {
  src: string,
  title: string,
  description?: string,
  alt?: string,
  url?: string
}

@Component({
  selector: 'app-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.css']
})
export class CarouselComponent implements OnInit {
  @Input() imgCollection: ImgCarousel[] = [];

  constructor() { }

  ngOnInit() {
  }

}
