import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-head-logo-simple',
  templateUrl: './head-logo-simple.component.html',
  styleUrls: ['./head-logo-simple.component.css']
})
export class HeadLogoSimpleComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
