import { Component, OnInit } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {ImgCarousel} from "../helper/carousel/carousel.component";

@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.css']
})
export class NewsComponent implements OnInit {
  href= "/assets/Img/story/noi.jpg";
  newsCollection: ImgCarousel[] = [
    /*{title:'noi', src:"News/Annusiamoci_meglio.jpg"},
    {title:'noi', src:"News/baby_parking.jpeg"},
    {title:'noi', src:"News/baby_parking.jpeg"},
    {title:'noi', src:"News/baby_parking.jpeg"},
    {title:'noi', src:"News/baby_parking.jpeg"},
    {title:'noi', src:"News/baby_parking.jpeg"},
    {title:'noi', src:"News/baby_parking.jpeg"},*/
    {title:'Space Memory', src:"News/SpaceMemoryGame.jpg", url:'https://www.flipbookpdf.net/web/site/fa925971cfd3eb0befb752653ddf3d39839824ca202004.pdf.html'},
  ];
  constructor(private feed: HttpClient  ) {

  }

  ngOnInit() {
  }

}
