import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { HomeComponent } from './pages/home/home.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { appRoutes } from "./routing/router";
import { RouterModule } from '@angular/router';
import { FooterComponent } from './components/footer/footer.component';
import { FacebookModule } from 'ngx-facebook';
import { SocialComponent } from './components/social/social.component';
import { OrariComponent } from './components/orari/orari.component';
import { PrezziComponent } from './components/prezzi/prezzi.component';
import { StoriaComponent } from './components/storia/storia.component';
import { PresentationComponent } from './components/presentation/presentation.component';
import { SharedModule } from './shared/shared.module';
import { ModalComponent } from './components/modal/modal.component';
import { CardComponent } from './components/helper/card/card.component';
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { CookieLawModule } from "angular2-cookie-law";
import { ParticlesComponent } from './components/helper/particles/particles.component';
import { TestComponent } from './components/helper/test/test.component';
import {ServiziComponent} from "./components/servizi/servizi.component";
import { ConvenzioniComponent } from './components/convenzioni/convenzioni.component';
import { FlipComponent } from './components/helper/flip/flip.component';
import { CarouselComponent } from './components/helper/carousel/carousel.component';
import { PolaroidComponent } from './components/helper/polaroid/polaroid.component';
import { TitleComponent } from './components/helper/title/title.component';
import { NewsComponent } from './components/news/news.component';
import {ReactiveFormsModule} from "@angular/forms";
import { NewsletterFormComponent } from './components/helper/newsletter-form/newsletter-form.component';
import { HeadLogoComponent } from './components/head-logo/head-logo.component';
import { DownloadComponent } from './pages/download/download.component';
import { HeadLogoSimpleComponent } from './components/head-logo-simple/head-logo-simple.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { GalleryComponent } from './pages/gallery/gallery.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    NavbarComponent,
    FooterComponent,
    SocialComponent,
    OrariComponent,
    PrezziComponent,
    StoriaComponent,
    ServiziComponent,
    PresentationComponent,
    ModalComponent,
    CardComponent,
    ParticlesComponent,
    TestComponent,
    ConvenzioniComponent,
    FlipComponent,
    CarouselComponent,
    PolaroidComponent,
    TitleComponent,
    NewsComponent,
    NewsletterFormComponent,
    HeadLogoComponent,
    DownloadComponent,
    HeadLogoSimpleComponent,
    GalleryComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    SharedModule,
    CookieLawModule,
    RouterModule.forRoot(
      appRoutes
    ),
    FacebookModule.forRoot(),
    ReactiveFormsModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
  ],
  providers: [
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
