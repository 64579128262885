import {AfterViewInit, Component, Directive, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
declare var particlesJS: any;
@Component({
  selector: 'app-particles',
  templateUrl: './particles.component.html',
  styleUrls: ['./particles.component.css']
})
export class ParticlesComponent implements OnInit {

  @Input() config : 'snow' | 'lines';
  @Input() isRounded: boolean = false;
  constructor() {
  }

  ngOnInit(): void {
    particlesJS.load('particles-js-'+this.config, `assets/particles/${this.config}.json`, null);
  }

}
