import {Component, HostListener, OnInit} from '@angular/core';
import {of} from "rxjs";

@Component({
  selector: 'app-presentation',
  templateUrl: './presentation.component.html',
  styleUrls: ['./presentation.component.css']
})
export class PresentationComponent implements OnInit {

  // @ts-ignore
  imagePath = require('../../../assets/Img/lavagna.jpg');
  constructor() {

  }

  ngOnInit() {
  }

}
