import {Routes} from "@angular/router";
import {HomeComponent} from "../pages/home/home.component";
import {PageNotFoundComponent} from "../pages/page-not-found/page-not-found.component";
import {DownloadComponent} from "../pages/download/download.component";
import {GalleryComponent} from "../pages/gallery/gallery.component";

export const appRoutes: Routes = [
    { path: '', component: HomeComponent},
    { path: 'download',   component: DownloadComponent},
    { path: 'gallery',   component: GalleryComponent},
    { path: '**', component: PageNotFoundComponent, data:{nav:false} }
  ];

