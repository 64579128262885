import { Component, OnInit } from '@angular/core';
import {environment} from "../../../environments/environment";


@Component({
  selector: 'app-social',
  templateUrl: './social.component.html',
  styleUrls: ['./social.component.css']
})
export class SocialComponent implements OnInit {

  fbUrl: string = environment.fb_url;
  constructor() {}

  ngOnInit() {
  }

  getFbWidth() {
    return window.innerWidth >= 1000?500:window.innerWidth >= 700?400:300;
  }
}
