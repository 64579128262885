import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import * as jsPDF from 'jspdf';
import html2canvas from "html2canvas";
import {Observable, Observer} from "rxjs";
import {Router} from "@angular/router";
@Component({
  selector: 'app-prezzi',
  templateUrl: './prezzi.component.html',
  styleUrls: ['./prezzi.component.css']
})
export class PrezziComponent implements OnInit {
  @ViewChild("content", {static: false}) content: ElementRef;
  private base64Image: string;
  constructor(private router: Router) { }

  ngOnInit() {

  }

  goToDownload() {
    this.router.navigate(['download']);
    /*
    let position = window.scrollY;
    let elem = document.getElementById('content');
    let doc = new jsPDF('p', 'pt', 'a4');
    //let img = canvas.toDataURL('image/png');
    Promise.resolve().then(()=>{
      return new Promise((resolve)=>{
        doc.addHTML(elem,()=>{
          resolve();
        });
      })
    }).then(()=>{
      doc.save('TariffeLaLunaAiBambini.pdf');
      window.scrollTo(0,position);
    })
    */
  }


}
