import {AfterViewInit, Component, OnChanges, OnInit} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {FacebookService} from "ngx-facebook";
import {FacebookServe} from "./services/fbService/facebook-serve.service";
import {ScrollSpyService} from "./services/scrollSpy/scroll-spy.service";
import {Location} from "@angular/common";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit,AfterViewInit {
  title = 'LaLunaAiBambini';
  isNavbarVisible: boolean = true;
  url = '';
  constructor(public scrollSpyService: ScrollSpyService, private location: Location){
    this.scrollSpyService.init();
    this.isNavbarVisible = true;
  }

  ngOnInit(): void {
    this.location.onUrlChange((url,state)=>{
      //console.log('location:',url,state);
      this.url = url;
      this.scrollSpyService.init();
      this.isNavbarVisible = url.includes('home') || url === '/';
      window.scrollTo(0,0)
    });
  }

  ngAfterViewInit(): void {
    setTimeout(()=>{
      window.scrollTo(0,0);
    },50)

  }

}
