import { Component, OnInit } from '@angular/core';
import {Link} from "../../classes/link/link";
import {environment} from "../../../environments/environment";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {FlipClass} from "../helper/flip/flip.component";

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  posLink = `https://wego.here.com/directions/mix//La-luna-ai-bambini,-Corso-Stamira-61,-60121-Ancona,-Italy:e-eyJuYW1lIjoiTGEgbHVuYSBhaSBiYW1iaW5pIiwiYWRkcmVzcyI6IkNvcnNvIFN0YW1pcmEgNjEsIEFuY29uYSIsImxhdGl0dWRlIjo0My42MTcxNSwibG9uZ2l0dWRlIjoxMy41MTM3OCwicHJvdmlkZXJOYW1lIjoiZmFjZWJvb2siLCJwcm92aWRlcklkIjozNTYxNjYyODQ3MzY2OTh9?map=43.61715,13.51378,15,normal&fb_locale=it_IT`;
  luogo = new Link('Corso Stamira 61,<wbr> 60121, AN', this.posLink,null, 'Ci trovi in','fas fa-map-marker-alt',null ,'text-danger');
  email = new Link('staff@lalunaaibambini.it', 'mailto:staff@lalunaaibambini.it', null,'Email della luna','fas fa-envelope',null,'text-info');
  fisso = new Link('071 9945072', 'tel:+39 071 9445072', null,'Lunoteca','fas fa-phone',null,'text-success');
  annaCell =  new Link('340 8157695', 'tel:+39 340 8157695',null,'Annalisa', 'fas fa-mobile-alt',null,'text-success');
  perlaCell= new Link('320 0552842', 'tel:+39 320 0552842',null,'Perla', 'fas fa-mobile-alt',null,'text-success');
  tablet = new Link('339 5341123', 'https://api.whatsapp.com/send?phone=393395341123', null,'Whatsapp Lunoteca','fab fa-whatsapp',null,'text-success');
  socName: string = 'La luna ai bambini s.n.c. di Rumori & Rossini <i class="fas fa-trademark"></i>';
  piva: string = 'CF/P.IVA 02727580421';
  siteMaker: string = '';
  fbUrl: string = environment.fb_url;

  facebook = new Link('facebook',environment.fb_url, null, null, "fab fa-facebook-f")
  instagram = new Link('instagram',environment.instagram_url, null, null, "fab fa-instagram")
  twitter = new Link('twitter',environment.twitter_url, null, null, "fab fa-twitter");
  google = new Link('google',environment.google_url, null, null, "fab fa-google");
  heroApiKey: any = "rEISaVd_h1Iqz0_upnnByPvzLZq1y1z2pObj6-OgDrw";
  newsletterFlip: FlipClass = {
    front: '<div>Iscriviti alla nostra NewsLetter</div>',
    back:'<app-newsletter-form></app-newsletter-form>',
    size:{w:'100%',h:'300px',max_h:'100%',max_w:'100%',min_h:'100%',min_w:'100%'}
  };


  constructor() { }

  ngOnInit() {
  }

}
