import {NgModule} from "@angular/core";
import {ParallaxComponent} from "../components/helper/parallax/parallax.component";
import {HttpClientModule} from "@angular/common/http";
import {PageNotFoundComponent} from "../pages/page-not-found/page-not-found.component";
import {IconComponent} from "../components/helper/icon/icon.component";
import {CommonModule} from "@angular/common";
import {FlipModule} from "ngx-flip";
import { DebugComponent } from './debug/debug.component';

const shared = [
  ParallaxComponent,
  PageNotFoundComponent,
  IconComponent,
];

const sharedImport = [
  CommonModule,
  HttpClientModule,
  FlipModule
];

@NgModule({
  declarations:[
    ...shared,
    DebugComponent,
  ],
  imports:[
    ...sharedImport,
  ],
    exports: [
        ...sharedImport,
        ...shared,
        DebugComponent,
    ]
})
export class SharedModule {

}
