import {
  Component,
  ElementRef,
  HostListener,
  Input,
  OnInit,
  AfterContentInit,
  ViewChild,
  AfterViewInit, Attribute
} from '@angular/core';
import {of} from "rxjs";

@Component({
  selector: 'app-parallax',
  templateUrl: './parallax.component.html',
  styleUrls: ['./parallax.component.css']
})
export class ParallaxComponent implements OnInit,AfterViewInit {

  exscroll = 0;
  pos = 0;
  lastPos = 0;
  cmpHeight = 0;
  @Input() image: string ;
  @Input() isFixed: boolean = false ;
  @Input() height: number = 1;
  @Input() multiplier: number = 0.15;
  @Input() startPosition: number = 50;
  @Input() parent: HTMLElement;
  @ViewChild('back', {static:false})background : ElementRef<HTMLElement>;
  offset: number = 0;
  @Input() isRadiusEffect: boolean = false;

  constructor() {
  }

  ngOnInit() {

  }

  ngAfterViewInit(): void {
    this.offset = this.parent.offsetTop ;
    this.cmpHeight = this.parent.offsetHeight;
    this.pos = 0;
    // @ts-ignore
    //console.log(this.image, this.background)
  }

  @HostListener('window:scroll', ['$event']) onScrollEvent($event){
    //console.log($event);
    let log: any[] = [];
    if(!this.isFixed) {
      if (window.scrollY >= this.offset - this.cmpHeight) {
        if (window.scrollY <= this.offset + this.cmpHeight) {
          let dir = -1;
          if ((this.exscroll - window.scrollY) > 0) {
            dir = 1;
          }
          this.exscroll = window.scrollY;
          this.pos += dir * this.multiplier;
          log = ["scrolling", this.cmpHeight, this.pos];
          this.lastPos = this.pos;
        } else {
          this.pos = this.lastPos;
          log = ["scrolling", "to last"];
        }
      } else {
        this.pos = 0;
        log = ["scrolling", "to top"];
      }
      /*if (this.image === 'back1.jpg') {
        console.log(log);
      }*/
    }
  }

  parallax() {
    return {
      "background-image":`url('${this.image}')`,
      "background-position-y": `${this.startPosition - this.pos}%`,
    }
  }




}
