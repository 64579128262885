import { Component, OnInit } from '@angular/core';
import {FlipClass} from "../helper/flip/flip.component";

@Component({
  selector: 'app-convenzioni',
  templateUrl: './convenzioni.component.html',
  styleUrls: ['./convenzioni.component.scss']
})
export class ConvenzioniComponent implements OnInit {
  size = {min_h:'400px', min_w:'400px', w:'400px', h:'400px'};
  convenzioniCollection: FlipClass[] = [
    {

      isFront: false,
      front:`<img src="../../../assets/Img/Icon/raval_family.png" class="img-auto-ratio">`,
      back:`<div class="pt-2 text-center">Scopri i vantaggi della convenzione con la Raval Family</div>`,
    },{
      isFront: false,
      front:`<img src="../../../assets/Img/Icon/ew.png" class="img-auto-ratio">`,
      back:`<div class="pt-2 h-100 text-center">Da noi puoi usare i tuoi welfare voucher!</div>`,
    },
  ];

  constructor() { }

  ngOnInit() {
  }

  toggle(flag: boolean) {
    return !flag;
  }
}
