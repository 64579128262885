import {Component, Input, OnInit} from '@angular/core';


export type FlipClass = {
  front: string,
  back: string,
  isFront?:boolean,
  size?:{
    w?:string,
    h?:string,
    max_w?:string
    min_w?:string
    max_h?:string
    min_h?:string
  }
}
@Component({
  selector: 'app-flip',
  templateUrl: './flip.component.html',
  styleUrls: ['./flip.component.scss']
})
export class FlipComponent implements OnInit {

  @Input() elem: FlipClass;
  constructor() {

  }

  ngOnInit() {
    if(this.elem) {
      if(this.elem.isFront === undefined)
        this.elem.isFront = false;
      if (!this.elem.size) {
        this.elem.size = {
          w: '100%',
          h: '100%',
        }
      }
    }
  }

  toggle(flag): boolean{
    return !flag;
  }

  getSize(elem) {
    if(elem && elem.size) {
      let size: any = {
        'height': elem.size.h,
        'width': elem.size.w,
      }
      if (elem.size.min_h)
        size = {...size, 'min-height': elem.size.min_h};
      if (elem.size.max_h)
        size = {...size, 'max-height': elem.size.max_h};
      if (elem.size.min_w)
        size = {...size, 'min-width': elem.size.min_w};
      if (elem.size.max_w)
        size = {...size, 'max-width': elem.size.max_w};
      return size;
    }
    return {}
  }

  fn(el: HTMLElement, isEnter: boolean) {
    //console.log(el)
    el.className = "";
    requestAnimationFrame(() => {
      requestAnimationFrame(() => {
        el.className = isEnter? "in": "out";
      });
    });
  }
}
