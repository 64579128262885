import { Component, OnInit } from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";

@Component({
  selector: 'app-newsletter-form',
  templateUrl: './newsletter-form.component.html',
  styleUrls: ['./newsletter-form.component.css']
})
export class NewsletterFormComponent implements OnInit {
  newsLetterForm = new FormGroup({
    nome: new FormControl('',Validators.required),
    cognome: new FormControl('',Validators.required),
    email: new FormControl('',[
      Validators.required,
      Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$")]),
  });

  constructor() { }

  ngOnInit() {
  }

  sendEmail() {
    console.warn(this.newsLetterForm.value);
  }
}
